import * as Sentry from '@sentry/browser';

// ----------------------------------------------------------------------
// https://docs.sentry.io/error-reporting/configuration/?platform=browser
// ----------------------------------------------------------------------

Sentry.init({
  dsn: window.PBS_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: [
    "https://www.pbs.org",
    "https://content.services.pbs.org",
  ],
  // we can only do something about scripts that either come from pbs.org or cloudfront
  allowUrls: [
    /https?:\/\/(\w*\.)?pbs\.org/i,
    /https?:\/\/(\w*\.)?cloudfront\.net/i,
  ],
  ignoreErrors: [
    // This comes from everywhere
    /Blocked a frame with origin/,
    // This seemingly comes from a browser extension
    // https://github.com/getsentry/sentry-javascript/issues/2210,
    /currentTarget, detail, isTrusted, target/,
    /Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target/,
    // These comes from JAWS, but do no harm
    // https://projects.pbs.org/jira/browse/JAWS-197
    /Cannot read property 'offsetWidth' of null/,
    /null is not an object \(evaluating 'arrowBox.offsetParent.offsetWidth'\)/,
    // These come from fetch requests being stopped manually before they complete
    // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2
    /TypeError: cancelled/,
    /TypeError: NetworkError when attempting to fetch resource./,
    // This error comes from Safari and Google Ads, but seems harmless
    /Non-Error promise rejection captured with value: undefined/,
    // There is nothing we can do about most of these 404s, so we should avoid slamming Sentry.
    // We'll just match the beginning of the error message
    /^HttpNotFoundError Unexpected http response status code: 404 sending GET request to/,
    // https://projects.pbs.org/jira/browse/RWEB-6793
    // Very noisey error that seems to be coming from Microsoft Servers
    /Non-Error promise rejection captured with value:/,
    // https://projects.pbs.org/jira/browse/RWEB-6754
    // Comes from the Instagram Browser, of all things
    /Can't find variable: _AutofillCallbackHandler/,
    // https://projects.pbs.org/jira/browse/RWEB-6931
    // This errors seems to be generated by scraper bots, which don't have local storage
    /^Error: QuotaExceededError: Failed to execute 'setItem' on 'Storage': Setting the value of/,
  ],
  sampleRate: 0.5, // a random sampling of 50% of events are sent to Sentry
  tracesSampleRate: 0.25,
});

Sentry.getCurrentScope().setTag('station', window.PBS_STATION_NAME);
